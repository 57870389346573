import "channels"
require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require("@nathanvda/cocoon")
require 'trix'

import UiKit from 'uikit'
import UiIcons from 'uikit/dist/js/uikit-icons.min'

# Stimulus Controllers
import 'controllers'

UiKit.use(UiIcons)

window.Swal = require 'sweetalert2/dist/sweetalert2.min'
